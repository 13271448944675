<template>
<section v-if="this.$route.params.id==29" class="bg-white-2">
<section class="pos-rel" style="    background: #f2f2f2;
">
  <div class="conOfEventNameImg">
            <img src="@/assets/img/event logo.svg" alt="event-img" class="img-fluid" />
             <v-btn
                  class="register-btn"
                  outlined
                
                  :href="'https://register.saudiblood.org/'"
                  target="_blank"
                  :ripple="false"
                >
                  REGISTER NOW
                </v-btn>

  </div>
            <img src="@/assets/img/event-head.png" alt="event-img" class="img-fluid" />
</section>

<section>
  <div>
    <img src="@/assets/img/partners.png" alt="event-img" class="img-fluid" />

  </div>

</section>
<section class="bg-white">
 
  <div class="conOfspeakers">
    <v-container>
    <v-row class="justify-center">
      <v-col cols="12">
         <div class="conOfSpeakersHead">
   <span class="red-ashc"> ASHC </span> <span class="blue-txt">SPEAKERS OF 2022</span>
  </div>
      </v-col>
 <v-col cols="12" md="10">
   <v-row>
      <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker1.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Anna Schuh, MD</div>
        <div class="dr-role">UK</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker2.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Khaled Musalam, MD</div>
        <div class="dr-role">UAE</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker3.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Tarek Owaidah, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker4.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Klaus Görlinger, MD</div>
        <div class="dr-role">GERMANY</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker5.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Régis Peffault De Latour, MD</div>
        <div class="dr-role">FRANCE</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker6.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Andreas Greinacher, MD</div>
        <div class="dr-role">GERMANY</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker7.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Alok Srivastava, MD</div>
        <div class="dr-role">INDIA</div>
  </div>
  </v-col>
  <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker8.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Paul Monagle, MD</div>
        <div class="dr-role">AUSTRALIA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker9.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Aljawhara Almanaa</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker10.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Armando Tripodi</div>
        <div class="dr-role">ITALY</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker11.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Francois Depasse, PharmD, MSc</div>
        <div class="dr-role">FRANCE</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker12.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Alfadel Alshaibani, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker13.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Mohamed Mohty, MD</div>
        <div class="dr-role">FRANCE</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker14.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Bader Ali Alahmari, Md</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker15.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Ludia Scarfo, MD</div>
        <div class="dr-role">ITALY</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker16.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Mark Litzow, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker17.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Hagop M. Kantarjian, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>

    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker18.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Jennifer Brown, MD</div>
        <div class="dr-role"> USA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker19.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Sameer Parikh, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker20.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Kieron Dunleavy , MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
   <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker21.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Joshua Richter, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
   <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker22.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Andres Ferreri, MD</div>
        <div class="dr-role">ITALY</div>
  </div>
  </v-col>
   <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker23.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Abdullah Al Jefri, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker24.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Jasmine Zain, MD</div>
        <div class="dr-role">CANADA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker25.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Andrea Biondi, MD</div>
        <div class="dr-role">ITALY</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker26.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Wasil Jastaniah, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker27.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Sumit Gupta</div>
        <div class="dr-role">CANADA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker28.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Todd M. Cooper, DO</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker29.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Michael Bishop, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker30.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Saeed Alturki, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker31.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Carl Allen, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker32.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Aaron Goodman, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker33.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Andrea Bacigulopo, MD</div>
        <div class="dr-role">ITALY</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker34.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Ronan Foley, MD</div>
        <div class="dr-role">CANADA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker35.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Nina Shah, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker36.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Divya Koura, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker37.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">David Lillicrap, MD</div>
        <div class="dr-role">CANADA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker38.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">David A. Williams, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker39.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Ian Winburn, PhD</div>
        <div class="dr-role">UK</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker40.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Niketa Shah, MD</div>
        <div class="dr-role">USA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker41.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Binyam Uthman</div>
        <div class="dr-role"></div>
  </div>
  </v-col>
 
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker42.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Riad Elfakih, MD</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker43.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Marwan Shaheen</div>
        <div class="dr-role">SAUDI ARABIA</div>
  </div>
  </v-col>
    <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker44.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">John Gribben , MD</div>
        <div class="dr-role">UK</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker45.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Wojciech Jurczak</div>
        <div class="dr-role">POLAND</div>
  </div>
  </v-col>
     <v-col
  cols="12" md="2" 
  >
  <div class="conOfspeaker">
        <img src="@/assets/img/speaker46.png" alt="event-img" class="img-fluid speakerImg" />
        <div class="dr-name">Riad Dada</div>
        <div class="dr-role"></div>
  </div>
  </v-col>
   </v-row>
 </v-col>
    </v-row>
    
    </v-container>
  </div>
</section>
<section class="bg-blue">
<v-container>
  <v-row>
    <v-col cols="12">
      <div class="text-center">
       <span class="redTxt">Event </span> <span class="WhiteTxt"> Agenda </span>
      </div>
    
    </v-col>
    <v-col cols="12" md="6" class="d-flex align-center">
<div>
  <v-row class="">
    <v-col cols="12">
     <span class="redTxt"> Annual </span><span class="WhiteTxt"> Saudi Hematology Congress</span>
    </v-col>
    <v-col cols="12">
  <div class="agenda-left-txt">
Please follow this link to register to the event <a href="https://register.saudiblood.org/" target="_blank"> https://register.saudiblood.org/</a>
  </div>


    </v-col>
    <v-col cols="12">
          <v-btn
                  class="register-btn"
                  outlined
                
                  :href="'https://register.saudiblood.org/'"
                  target="_blank"
                  :ripple="false"
                >
                  REGISTER NOW
                </v-btn>
    </v-col>
  </v-row>
</div>
    </v-col>
     <v-col cols="12" md="6" >
   
       
       <div class="agenda-day">
         <div class="day">Day 1</div>
SAUDI HEMATOLOGY RESEARCH MEETING (VIRTUAL)
       </div>
             <div class="agenda-day">
         <div class="day">Day 2</div>
BENIGN HEMATOLOGY (VIRTUAL)
       </div>
        <div class="agenda-day">
         <div class="day">Day 3,4</div>
MALIGNANT HEMATOLOGY (VIRTUAL)     </div>
 <div class="agenda-day">
         <div class="day">Day 5</div>
PEDIATRIC HEMATOLOGY (VIRTUAL)    </div>
 <div class="agenda-day">
         <div class="day">Day 6,7</div>
HSCT/CAR-T (VIRTUAL)    </div>
    </v-col>
  </v-row>
</v-container>
</section>
<section class="bg-white">

 <div class="conOfSpeakersHead">
   <span class="red-ashc"> ASHC </span> <span class="blue-txt">WORKSHOPS</span>
  </div>
  <v-container>
  <v-row class="justify-center">
    <v-col cols="12" md="4">
     <div class="workshop1 d-flex align-center justify-center">
       <div class="workshopTxt">
         Advancing Nursing in Hematological Oncology
       </div>
     </div>
    </v-col>
      <v-col cols="12" md="4">
     <div class="workshop2 d-flex align-center justify-center">
        <div class="workshopTxt">
        Laboratory Technical in Benign Hematology
       </div>
     </div>
    </v-col>
  </v-row>
    <v-row class="justify-center">
    <v-col cols="12" md="4">
     <div class="workshop3 d-flex align-center justify-center">
        <div class="workshopTxt">
Hematology Professional Manpower in Saudi Arabia       </div>
     </div>
    </v-col>
      <v-col cols="12" md="4">
     <div class="workshop4 d-flex align-center justify-center">
        <div class="workshopTxt">
        Pharmaceutical Industry & Hematology in Saudi
       </div>
     </div>
    </v-col>
  </v-row>
  </v-container>
</section>
<section class="bg-lightred">
<v-container class="h-100">
  <v-row class="justify-center align-center h-100">
    <v-col cols="12" md="5">
      <div class="updated">
        STAY UPDATED
      </div>
      <div class="latestUpdate">
        Subscribe To Our Newsletter To Get The Latest Updates
      </div>
         <div class="conSubscribe d-flex align-baseline">
                  <v-text-field
                    class="subScribeInput"
                    solo
                    hide-details
                    elevation-0
                    placeholder="Enter Email Address"
                    aria-label="Search"
                  />
                  <v-btn class="subscibeBtn register-btn ml-2"> subscribe </v-btn>
                </div>
    </v-col>
  </v-row>
</v-container>
</section>
<section class="bg-white" >
   <v-container>
    <v-row class="justify-center">
      <v-col cols="12">
         <div class="conOfSpeakersHead">
   <span class="red-ashc"> ASHC </span> <span class="blue-txt">SPONSORS</span>
  </div>
    <div class="d-flex justify-center">
    <img src="@/assets/img/partners2.png" alt="event-img" class="img-fluid" />

  </div>

      </v-col>
  </v-row>
     </v-container>
</section>
</section>
  <section class="conOfEventItem h-100 mb-5" v-else>
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="itemResponse && itemResponse.length > 0">
      <div>
        <v-container>
          <section class="conOfHeader">
            <v-row>
              <v-col md="8" cols="12">
                <div class="headerSec">{{ itemResponse[0].title }}</div>
              </v-col>
              <!-- <v-col md="4" cols="12">
                <div class="shareBtnCon text-right">
                  <v-btn class="eventShareBtn" text :ripple="false">
                    share
                    <v-icon>mdi-share-variant</v-icon>
                  </v-btn>
                </div>
              </v-col> -->
            </v-row>
          </section>
        </v-container>
      </div>

      <v-container>
        <v-row>
          <v-col md="6" cols="12">
            <div class="conofcardSec">
              <div class="descSec" v-html="itemResponse[0].description"></div>
              <div class="btnCon" v-if="itemResponse[0].has_url">
                <v-btn
                  class="primaryBtn bookNow my-5"
                  text
                  :href="itemResponse[0].url"
                  :ripple="false"
                  target="_blank"
                >
                  Book your place now
                </v-btn>
              </div>
              <div
                class="conOfAgenda"
                v-if="
                  itemResponse[0].agenda && itemResponse[0].agenda.length > 0
                "
              >
                <div class="titleAgendaTxt">Agenda</div>
                <div
                  class="conTimeLine"
                  v-for="(item, index) in itemResponse[0].agenda"
                  :key="index"
                >
                  <v-timeline align-top dense>
                    <div class="conOfTime">{{ item.time }}</div>

                    <v-timeline-item small>
                      <v-row class="pt-2 mrgLefMin">
                        <v-col>
                          <div class="conOfAgendaCont">
                            <div class="headerAgenda">
                              {{ item.title }}
                            </div>
                            <div
                              class="descAgenda"
                              v-html="item.description"
                            ></div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div class="conSecImage">
              <div class="conOfCardImg">
                <v-img
                  :src="itemResponse[0].image"
                  alt="item of the Image"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "Annual Congress",
        disabled: true,
      },
      {
        text: "Event",
        disabled: false,
      },
    ],
    itemResponse: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
    Breadcrumb,
  },
  methods: {
    getListData() {
      this.itemResponse = [];
      this.isLoading = true;

      apiServices.get("events-item/" + this.$route.params.id).then((res) => {
        if (res) {
          this.itemResponse = res.data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_event-item.scss";
</style>
